import React, { useRef, useEffect } from "react"
import Swiper from "react-id-swiper"
import "swiper/swiper-bundle.css"

export default function SwiperContainer({
  children = [],
  name = "",
  changeSlide = 0,
  sliderChange = () => {},
}) {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideTo(changeSlide)
    }
  }, [changeSlide])

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: 30,
    on: {
      slideChange: ({ realIndex }) => {
        sliderChange(realIndex)
      },
    },
  }

  return (
    <div className="relative">
      {children.length > 0 && (
        <Swiper {...params} ref={ref}>
          {children.map((child, index) => (
            <div key={`swiper-${name}-${index}`}>{child}</div>
          ))}
        </Swiper>
      )}

      <button
        className="absolute left-0 p-0 lg:p-2 z-30 outline-none clickable focus:outline-none"
        style={{ top: "50%" }}
        onClick={goPrev}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        >
          <path d="M15 18l-6-6 6-6" />
        </svg>
      </button>
      <button
        className="absolute right-0 p-0 lg:p-2 z-30 outline-none clickable focus:outline-none"
        style={{ top: "50%" }}
        onClick={goNext}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </button>
    </div>
  )
}
