import React from "react"

import flare from "./../images/flare.png"

import "../styles/flare.scss"

export default function FlareSeparator() {
  return (
    <div className="mt-24 w-screen flex justify-center">
      <div className="flare">
        <img src={flare} alt="Flare" />
      </div>
    </div>
  )
}
